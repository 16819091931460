
function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
    let myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl']
    }, {
      searchControlProvider: 'yandex#search'
    });

    let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [43, 57],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-21, -57]
    });

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
    myMap.geoObjects.add(myPlacemark);
  });
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});