require('../scss/app.scss');

require('../fonts/Gilroy/styles.css');

require('./js_validation.js');
require('./links.js');
require('./main.js');
require('./main_slider.js');
require('./map.js');

//
// require('./utils.js');
