import slick from "slick-carousel";

let $slider = $('[data-main-slider]');
let $window = $(window);
let on = false;

if ($slider.length > 0) {
  $window.on('resize check-slick', () => {
    if (($window.width() > 1279) && !on) {
      on = true;
      $slider = $('[data-main-slider]');
      $slider.slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: true,
        infinite: false
      });
    } else if (on) {
      $slider.slick('unslick');
      on = false;
    }
  }).trigger('check-slick');
}

